import { TauiConfig } from "../types/config";

export const tauiFakeHost =
  "https://adminui.staging-red-2022-03.labs.tucano.red";

export const tauiFakeConfig: TauiConfig = {
  backendHttpApi: `${tauiFakeHost}/back/`,
  platformLanguage: "eng",
  platformSupportedLanguages: ["ara", "eng", "spa", "fra", "por"],
  uiLanguages: ["ar", "en", "en-GB", "es", "fr", "pt-BR"],
  skin: "tucano",
  oauthClientId: "tucanoAdminUI",
  esIndexPrefix: "localhost_analytics",
  deviceConnectedSince: "10m",
  rememberTab: true,
  embedPlayer: true,
  playerTakeScreenshot: false,
  offerFtaCreation: true,
  veygoKey: "ANBeemixDevelopment22Y3zJchM6dojZp5",
  deviceType: [
    { value: "*android*", name: "Android", backgroundColor: "#65738e" },
    { value: "other_bucket_key", name: "Others", backgroundColor: "#000000" },
    { value: "*windows*", name: "Windows", backgroundColor: "#4d4f4a" },
    { value: "*ios*", name: "iOS", backgroundColor: "#a92a2c" },
  ],
  components: [
    {
      displayName: "adminui",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "akamai",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "analytics",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "apigateway",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "apple",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "applesso",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "billing",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "broadpeak",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "catchup",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "cloudfront",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "cms",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "crm",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "dashboard",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "entitlements",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "epgfiller",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "export",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "facebooksso",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "firebase",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "google",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "googlesso",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "gracenote",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "harmonic",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "identity",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "image",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "ipinfo",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "irdeto",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "npvr",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "oidc",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "openapi",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "plurimedia",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "privacy",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "publication",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "qa",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "rbroadpeak",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "realtime",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "recommendation",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "rqa",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "rtws",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "search",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "spideo",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "stripe",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "studio",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "suboffer",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "tmdb",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "tucano",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "export",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "viaccess",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
    {
      displayName: "vubiquity",
      version: "",
      buildNumber: "",
      buildTime: 0,
    },
  ],
  dashboardRefreshTime: "300000",
  resourceGroupsLabels: ["brand", "reseller"],
  realTimeMessageActionType: [
    { value: "OPEN_APP", name: "Open app", extra: null },
    { value: "GO_TO_CHANNEL", name: "Go to channel", extra: "id_channel" },
  ],
  uploadChunkSize: 1048576,
  uploadChunkSimultaneous: 3,
  uploadMaxChunkRetries: 10,
  uploadSimultaneous: 1,
  vodStudioFileExtensionTechnology: [
    { fileExtension: "m3u8", technology: "hls" },
    { fileExtension: "ism", technology: "hss" },
    { fileExtension: "mpd", technology: "dash" },
  ],
  homepageOpenElementMaximumRails: 50,
  homepageOpenElementMaximumRailSize: 50,
  publicationHomepageDynamicContentTimeframe: 2,
  publicationHomepageScreenFormat: [
    {
      value: "WEB",
      name: "Web",
      landscapeItems: 8,
      posterItems: 10,
      ratio: "1920/1080",
    },
    {
      value: "SMARTPHONE",
      name: "Smartphone",
      landscapeItems: 2,
      posterItems: 3,
      ratio: "360/640",
    },
    {
      value: "TABLET",
      name: "Tablet",
      landscapeItems: 4,
      posterItems: 5,
      ratio: "768/1024",
    },
    {
      value: "SMARTTV",
      name: "Smart TV",
      landscapeItems: 8,
      posterItems: 10,
      ratio: "1920/1080",
    },
  ],
  publicationHomepageTheme: [
    { value: "default", backgroundColor: "#ffffff", textColor: "#000000" },
    { value: "tucano", backgroundColor: "#ffffff", textColor: "#e42a2b" },
    { value: "bee", backgroundColor: "#1d1e1c", textColor: "#fab900" },
  ],
  publicationEditorialSectionTypes: ["articles", "news", "pages"],
  vodCatalog: [{ idCatalog: "1", cdnPlayIcon: true, originPlayIcon: true }],
  mediaTechnology: ["hls", "dash", "mp4", "ts", "mov", "hss"],
  csvDelimiter: ";",
  uploadAllowedFileTypesVideos: ".mpg,.mpeg,.ts,.mp4,.mp3,.mcp,.mxf,.mkv",
  uploadAllowedFileTypesImages: ".jpg,.jpeg,.svg,.png",
  sentryConfig: {
    enabled: false,
    dsn: "https://c00c5b6cc7b941948e8adfc33b2060dc@sentry.labs.tucano.red/6020613",
    environment: "development",
    debug: true,
    showReportDialog: true,
  },
  debugWebsocket: true,
  debugPlayer: true,
  vodTaggingEnabled: true,
  currency: "USD",
  defaultValues: {
    // @ts-ignore
    client: {
      enabled: true,
      trusted: true,
      extendsRefreshTokenValidity: true,
      refreshTokenValidity: 5184000000,
      accessTokenValidity: 14400000,
      otpMode: "NONE",
      pairingCodeConfiguration: {
        acceptedCharacters: "2346789AZERTYPMKJHGFQWXCBN",
        length: 6,
        validity: 900000,
      },
    },
    globalImage: {
      active: true,
    },
    epgProvider: {
      enabled: true,
      nextUpdateFunction:
        "var dt = new Date();dt.setDate(dt.getDate()+1);dt.setHours(24,0,0,0);dt.getTime();",
      updateFrequency: 86400000,
      minimumInterval: 3600000,
    },
    // @ts-ignore
    passwordPolicy: {
      minimumCharacters: 8,
      specialCharacterEnabled: false,
      specialCharacters: "\"#'{([- |_ç@)]=}+°,?;.:/!§%*$<>²",
    },
    vodCatalogSource: {
      nextUpdateFunction:
        "var dt = new Date();dt.setDate(dt.getDate()+1);dt.setHours(24,0,0,0);dt.getTime();",
      updateFrequency: 86400000,
      minimumInterval: 3600000,
      enableAutoDeprovisioning: true,
    },
    vodCatalogPublication: {
      enabled: true,
      editMode: "HALF_LOCKED",
      updateFrequency: 86400000,
      minimumInterval: 0,
    },
  },
};
